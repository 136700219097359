import { Form } from 'react-bootstrap';
function FormItem(props) {
   const { label, message, children } = props;
   return (
      <Form.Group className='mb-3 custom-form-group'>
         <Form.Label>{label}</Form.Label>
         {children}
         <Form.Text className='text-muted ok'>{message}</Form.Text>
      </Form.Group>
   );
}

function Text(props) {
   const { type, value, change, placeholder } = props;
   return <Form.Control type={type} value={value} onChange={change} placeholder={placeholder} />;
}
function InputDate(props) {
   const { type, value } = props;
   return <input className='form-control' type={type} value={value} />;
}
function Select(props) {
   const { render } = props;
   return (
      <Form.Select aria-label='Default select example'>
         <Option v='' t='--- select one ---' />
         {render ? render() : ''}
      </Form.Select>
   );
}
function Option(props) {
   const { v, t } = props;
   return <option value={v}>{t}</option>;
}
FormItem.defaultProps = {
   label: '',
   message: '',
};
Text.defaultProps = {
   type: 'text',
   change: (e) => {},
};
InputDate.defaultProps = {
   type: 'date',
};
export { FormItem, Text, Option, Select, InputDate };
