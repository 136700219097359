import { combineReducers } from 'redux';
import { counter } from './counter';
import { ads } from './ads';
import { auth } from './auth';
import { nav } from './nav';
import { users } from './users';
import { cars } from './cars';
import { popup } from './popup';
import { sidenav } from './sidenav';
const root = combineReducers({
   counter: counter,
   ads: ads,
   auth: auth,
   nav: nav,
   users: users,
   cars: cars,
   popup: popup,
   sidenav: sidenav,
});

export default root;
