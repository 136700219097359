import { useSelector, useDispatch } from 'react-redux';
import PopUp from '../../components/Popup';
import { ActionButton } from '../../components/ActionButton';
import { Row, Col } from 'react-bootstrap';
import Alert from '../../components/Alert';
import { FormItem, Text, Select } from '../../components/CustomFormItem';
import { popupkey } from '../../constant/popupkey';
import { hidePopup } from '../../redux/action/popup';
function CreateProduct(props) {
   //const state=useSelector((state)=>state:stat)
   const state = useSelector((state) => state.popup);
   console.log(state);
   const dispatch = useDispatch();

   return (
      <>
         <PopUp
            show={state.display[popupkey.C_PRODUCT]}
            close={(e) => {
               hidePopup(dispatch, popupkey.C_PRODUCT);
            }}
            title='CREATE PRODUCT'
         >
            <Row className='w-100 h-100 m-0'>
               <Col md={12}>
                  <Alert msg='Successfully product created.' status='1' show={true} />
               </Col>
               <Col md={6}>
                  <FormItem label='Category'>
                     <Select />
                  </FormItem>
               </Col>
               <Col md={6}>
                  <FormItem label='Sub Category'>
                     <Select />
                  </FormItem>
               </Col>
               <Col md={12}>
                  <FormItem label='Barcode Number'>
                     <Text />
                  </FormItem>
               </Col>
               <Col md={12}>
                  <FormItem label='Name 1'>
                     <Text />
                  </FormItem>
               </Col>
               <Col md={12}>
                  <FormItem label='Name 2'>
                     <Text />
                  </FormItem>
               </Col>

               <Col md={4}>
                  <FormItem label='Quantity'>
                     <Text />
                  </FormItem>
               </Col>
               <Col md={4}>
                  <FormItem label='Cost Price'>
                     <Text />
                  </FormItem>
               </Col>
               <Col md={4}>
                  <FormItem label='Max Price'>
                     <Text />
                  </FormItem>
               </Col>

               <Col md={4}>
                  <FormItem label='Sell Price'>
                     <Text />
                  </FormItem>
               </Col>
               <Col md={4}>
                  <FormItem label='Cash Price'>
                     <Text />
                  </FormItem>
               </Col>
               <Col md={4}>
                  <FormItem label='Special Price'>
                     <Text />
                  </FormItem>
               </Col>

               <Col>
                  <div className='w-100 d-flex justify-content-between'>
                     <button className='btn btn-primary'>
                        <span style={{ marginLeft: 5 }}>Add New</span>
                     </button>
                     <button className='btn btn-success'>
                        <span style={{ marginLeft: 5 }}>Save</span>
                     </button>
                  </div>
               </Col>
            </Row>
         </PopUp>
      </>
   );
}

export default CreateProduct;
