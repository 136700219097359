function AppButton(props) {
   const { cls, ico, text, load, click } = props;
   return (
      <button className={`btn app-button curve uncopy ${cls}`} disabled={load} onClick={click}>
         {load ? (
            <span class='spinner-border spinner-border-sm app-spinner uncopy' role='status'></span>
         ) : (
            <>
               {ico ? <ion-icon name={ico}></ion-icon> : ''}
               {text ? <span className='app-btn-text uncopy'>{text}</span> : ''}
            </>
         )}
      </button>
   );
}
AppButton.defaultProps = {
   load: false,
   click: (e) => {},
};
export default AppButton;
