const appUrl = 'https://cardeals.qa';
//const appUrl = 'http://127.0.0.1:8000';

const api = {
   getAds: appUrl + '/api/ads',
   authLogin: appUrl + '/api/auth/admin/login',
   changeAdsStatus: (s, t, c) => `${appUrl}/api/admin/change-car-status?cid=${c}&status=${s}&token=${t}`,
   deleteCar: appUrl + '/api/admin/delete/ads',
   getUser: appUrl + '/api/user',
   createUser: appUrl + '/api/admin/create/user',
   getBrand: appUrl + '/api/admin/car/brand',
   createBrand: appUrl + '/api/admin/brand/create',
   createFeature: appUrl + '/api/admin/feature/create',
   createModel: appUrl + '/api/admin/model/create',
   getUserInfo: appUrl + '/api/admin/user/info?uid=',
   updateUser: appUrl + '/api/admin/user/update',
   deleteUser: appUrl + '/api/admin/user/delete',
   getBodyType: appUrl + '/api/admin/car/bodytype',
   getCarFeature: appUrl + '/api/admin/car/feature',
};

export { appUrl };

export default api;
