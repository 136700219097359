import React from 'react';

function Alert(props) {
   const { msg, status, show, close } = props;
   return (
      <div className={`alert app-alert ${status === '1' ? 'ok' : 'error'}  ${!show ? 'd-none' : ''}`}>
         <span>{msg}</span>
         <button className={` btn alert-close-btn ${status === '1' ? 'btn-success' : 'btn-danger'} `} onClick={close}>
            <ion-icon name='close'></ion-icon>
         </button>
      </div>
   );
}
Alert.defaultProps = {
   close: (e) => {},
};

export default Alert;
