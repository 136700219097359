const popupkey = {
   //PRODUCT KEY
   C_PRODUCT: 'C_PRODUCT',
   D_PRODUCT: 'D_PRODUCT',
   U_PRODUCT: 'U_PRODUCT',
   //CATEGORY KEY
   C_CATEGORY: 'C_CATEGORY',
   U_CATEGORY: 'U_CATEGORY',
   D_CATEGORY: 'D_CATEGORY',
};

export { popupkey };
