import React from 'react';

//components
import { DataTable, DataTableHead, DataTableFooter, DataTableBody, Table, TableHead, TableBody, TableRaw, TableData } from '../../components/DataTable';
import AddStock from './AddStock';
import { Filter, FilterItem } from '../../components/Filter';
function Stock(props) {
   return (
      <div className='app-content'>
         <DataTable>
            <DataTableHead title='All Stock In History'>
               <AddStock />
            </DataTableHead>

            <DataTableBody>
               <Filter>
                  <FilterItem label='Barcode' />
                  <FilterItem label='Min Price' />
                  <FilterItem label='Max Price' />
                  <FilterItem label='Name' />
                  <FilterItem label='Batch No' type='select' />
                  <FilterItem label='Category' type='select' />
                  <FilterItem label='Sub Category' type='select' />
                  <FilterItem label='Delivery' type='select' />
                  <FilterItem label='Delivery Date' type='date' />
               </Filter>
               <Table>
                  <TableHead head='Barcode,Product Name,Batch No,Quantity,Price,Receiver,Supplier,Delivery Mode,Delivery Date,Remark' />
                  <TableBody>
                     <TableRaw>
                        <TableData>15</TableData>
                        <TableData>Pepsi</TableData>
                        <TableData>BN103</TableData>
                        <TableData>24</TableData>
                        <TableData>Rs 53.00</TableData>
                        <TableData>Sakeen</TableData>
                        <TableData>Royaltech pvt ltd</TableData>
                        <TableData>Van</TableData>
                        <TableData>10/Dec/2021</TableData>
                        <TableData>10</TableData>
                     </TableRaw>
                  </TableBody>
               </Table>
            </DataTableBody>
            <DataTableFooter></DataTableFooter>
         </DataTable>
      </div>
   );
}

export default Stock;
