import React from 'react';

//components
import { DataTable, DataTableHead, DataTableFooter, DataTableBody, Table, TableHead, TableBody, TableRaw, TableData } from '../../components/DataTable';
import Badge from '../../components/Badge';
import CreateInvoice from './CreateInvoice';
import { Filter, FilterItem } from '../../components/Filter';
function Sales(props) {
   return (
      <div className='app-content'>
         <DataTable>
            <DataTableHead title='All Invoices'>
               <CreateInvoice />
            </DataTableHead>

            <DataTableBody>
               <Filter>
                  <FilterItem label='Barcode' />
                  <FilterItem label='Min Price' />
                  <FilterItem label='Max Price' />
                  <FilterItem label='Name' />
                  <FilterItem label='Batch No' type='select' />
                  <FilterItem label='Category' type='select' />
                  <FilterItem label='Sub Category' type='select' />
                  <FilterItem label='Delivery' type='select' />
                  <FilterItem label='Delivery Date' type='date' />
               </Filter>
               <Table>
                  <TableHead head='Invoice No,Customer Name,Amount,Ref,Sale Type,Payment Status,Payment Method,Date' />
                  <TableBody>
                     <TableRaw>
                        <TableData>IN25</TableData>
                        <TableData>Sakeen</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>Someone</TableData>
                        <TableData>Van</TableData>
                        <TableData>
                           <Badge title='paid' cls='bg-success' />
                        </TableData>
                        <TableData>Cash</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>IN25</TableData>
                        <TableData>Sakeen</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>Someone</TableData>
                        <TableData>Van</TableData>
                        <TableData>
                           <Badge title='paid' cls='bg-success' />
                        </TableData>
                        <TableData>Cash</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>IN25</TableData>
                        <TableData>Sakeen</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>Someone</TableData>
                        <TableData>Van</TableData>
                        <TableData>
                           <Badge title='paid' cls='bg-success' />
                        </TableData>
                        <TableData>Cash</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>IN25</TableData>
                        <TableData>Sakeen</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>Someone</TableData>
                        <TableData>Van</TableData>
                        <TableData>
                           <Badge title='paid' cls='bg-success' />
                        </TableData>
                        <TableData>Cash</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>IN25</TableData>
                        <TableData>Sakeen</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>Someone</TableData>
                        <TableData>Van</TableData>
                        <TableData>
                           <Badge title='paid' cls='bg-success' />
                        </TableData>
                        <TableData>Cash</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>IN25</TableData>
                        <TableData>Sakeen</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>Someone</TableData>
                        <TableData>Van</TableData>
                        <TableData>
                           <Badge title='paid' cls='bg-success' />
                        </TableData>
                        <TableData>Cash</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>{' '}
                     <TableRaw>
                        <TableData>IN25</TableData>
                        <TableData>Sakeen</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>Someone</TableData>
                        <TableData>Van</TableData>
                        <TableData>
                           <Badge title='paid' cls='bg-success' />
                        </TableData>
                        <TableData>Cash</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>IN25</TableData>
                        <TableData>Sakeen</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>Someone</TableData>
                        <TableData>Van</TableData>
                        <TableData>
                           <Badge title='paid' cls='bg-success' />
                        </TableData>
                        <TableData>Cash</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>{' '}
                     <TableRaw>
                        <TableData>IN25</TableData>
                        <TableData>Sakeen</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>Someone</TableData>
                        <TableData>Van</TableData>
                        <TableData>
                           <Badge title='paid' cls='bg-success' />
                        </TableData>
                        <TableData>Cash</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                  </TableBody>
               </Table>
            </DataTableBody>
            <DataTableFooter></DataTableFooter>
         </DataTable>
      </div>
   );
}

export default Sales;
