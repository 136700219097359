import { FormItem, Text } from './CustomFormItem';
import AppButton from './AppButton';
function DeleteConformation(props) {
   const { msg, ico, label, del, cancel } = props;
   return (
      <div className='app-delete-conf'>
         <div className='app-del-icon'>
            <ion-icon name={ico}></ion-icon>
         </div>
         <div className='app-del-msg'>
            <span>{msg}</span>
         </div>
         <div className='app-del-input'>
            <FormItem label={label}>
               <Text placeholder='Eg : 102' />
            </FormItem>
         </div>
         <div className='app-del-action'>
            <AppButton text='CANCEL' cls='btn-primary' click={cancel} />
            <AppButton text='DELETE' cls='btn-danger' del={del} />
         </div>
      </div>
   );
}

DeleteConformation.defaultProps = {
   msg: 'Do you really want to delete these records? This process cannot be undone.',
   ico: 'trash-outline',
   label: 'Ender Id',
   del: (e) => {},
   cancel: (e) => {},
};

export { DeleteConformation };
