import { useSelector, useDispatch } from 'react-redux';
import PopUp from '../../components/Popup';

import Alert from '../../components/Alert';
import { popupkey } from '../../constant/popupkey';
import { hidePopup } from '../../redux/action/popup';
//component
import { DeleteConformation } from '../../components/DeleteConformation';
function DeleteProduct(props) {
   const state = useSelector((state) => state.popup);
   const dispatch = useDispatch();

   return (
      <PopUp show={state.display[popupkey.D_PRODUCT]} close={(e) => hidePopup(dispatch, popupkey.D_PRODUCT)} title='DELETE PRODUCT #102'>
         <div className='w-100 h-100 m-0'>
            <DeleteConformation label='Ender product id' cancel={(e) => hidePopup(dispatch, popupkey.D_PRODUCT)} />
         </div>
      </PopUp>
   );
}

export default DeleteProduct;
