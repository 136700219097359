import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, useAccordionButton } from 'react-bootstrap';

//components
import { DataTable, DataTableHead, DataTableFooter, DataTableBody } from '../../components/DataTable';
import DeleteCategory from './DeleteCategory';
//Lib
import { ChangeState } from '../../lib/ChangeState';
//action
import { showPopup } from '../../redux/action/popup';
//constant
import { popupkey } from '../../constant/popupkey';

function Category() {
   const dispatch = useDispatch();
   return (
      <div className='app-content'>
         <div className='row m-0 p-0'>
            <div className='col-12 col-md-7'>
               <DataTable>
                  <DataTableHead title='All Categories' sub='Total : 10' />

                  <DataTableBody>
                     <div className='d-flex flex-column'>
                        <MainCategory
                           eKey='6'
                           catName='main derinks'
                           del={(e) => {
                              showPopup(dispatch, popupkey.D_CATEGORY);
                           }}
                        >
                           <>
                              <SubCategory
                                 subName='sub Drinks'
                                 del={(e) => {
                                    showPopup(dispatch, popupkey.D_CATEGORY);
                                 }}
                              />
                              <AddCategory placeholder='Add New Sub Category' />
                           </>
                        </MainCategory>
                     </div>
                  </DataTableBody>
                  <DataTableFooter>
                     <AddCategory placeholder='Add New Category' />
                  </DataTableFooter>
               </DataTable>
            </div>
         </div>

         {/*-------------------------------------[ POPUP COMPONENTS]------------------------------------*/}
         <DeleteCategory />
         {/*-------------------------------------[ END POPUP COMPONENTS]------------------------------------*/}
      </div>
   );
}

function CategoryList(props) {
   return <div className='category-list-item'>{props.children}</div>;
}

function CategoryListName(props) {
   const { name } = props;
   return <span>{name}</span>;
}
function CategoryListItemBtn(props) {
   const { ico, click } = props;
   return (
      <button className='btn' onClick={click}>
         <ion-icon name={ico}></ion-icon>
      </button>
   );
}

function CategoryExpandBtn(props) {
   const { eKey } = props;
   const [state, setState] = useState({ expand: false });
   const togleEve = useAccordionButton(eKey, () => setState(ChangeState(state, { expand: state.expand ? false : true })));
   return <CategoryListItemBtn ico={state.expand ? 'chevron-up' : 'chevron-down'} click={togleEve} />;
}
function InputText(props) {
   const { value, placeholder } = props;
   return <input type='text' value={value} placeholder={placeholder} className='form-control' />;
}

function MainCategory(props) {
   const { eKey, catName, children, save, del } = props;
   const defaultState = { edit: false };
   const [state, setState] = useState(defaultState);

   return (
      <Accordion className='d-flex flex-column'>
         <CategoryList>
            <CategoryExpandBtn ico='chevron-down' eKey={eKey} />
            {state.edit ? <InputText /> : <CategoryListName name={catName} />}
            <CategoryListItemBtn
               ico={state.edit ? 'close' : 'create-outline'}
               click={(e) => {
                  setState(ChangeState(state, { edit: state.edit ? false : true }));
               }}
            />
            <CategoryListItemBtn
               ico={state.edit ? 'checkmark' : 'trash-outline'}
               click={(e) => {
                  state.edit ? save() : del();
               }}
            />
         </CategoryList>
         <Accordion.Collapse eventKey={eKey} className='category-sub-list-item'>
            {children}
         </Accordion.Collapse>
      </Accordion>
   );
}

function SubCategory(props) {
   const { subName, save, del } = props;
   const defaultState = { edit: false };
   const [state, setState] = useState(defaultState);
   return (
      <CategoryList>
         {state.edit ? <InputText /> : <CategoryListName name={subName} />}

         <CategoryListItemBtn
            ico={state.edit ? 'close' : 'create-outline'}
            click={(e) => {
               setState(ChangeState(state, { edit: state.edit ? false : true }));
            }}
         />
         <CategoryListItemBtn
            ico={state.edit ? 'checkmark' : 'trash-outline'}
            click={(e) => {
               state.edit ? save() : del();
            }}
         />
      </CategoryList>
   );
}

function AddCategory(props) {
   const { placeholder, save } = props;
   return (
      <CategoryList>
         <InputText placeholder={placeholder} />
         <CategoryListItemBtn ico='add' click={save} />
      </CategoryList>
   );
}

MainCategory.defaultProps = {
   save: (e) => {
      console.log('save');
   },
   del: (e) => {
      console.log('del');
   },
};

SubCategory.defaultProps = {
   save: (e) => {
      console.log('save');
   },
   del: (e) => {
      console.log('del');
   },
};

CategoryListItemBtn.defaultProps = {
   click: (e) => {},
};

AddCategory.defaultProps = {
   save: (e) => {},
};
export default Category;
