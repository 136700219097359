import AppButton from './AppButton';

function Filter(props) {
   const { children, reset, filter } = props;
   return (
      <div className='filter-ui '>
         {children}
         <div className='filter-item flex-row'>
            <AppButton ico='filter-sharp' cls='btn-primary' click={filter} />
            <AppButton ico='sync' cls='btn-danger' click={reset} />
         </div>
      </div>
   );
}

function FilterItem(props) {
   const { label, type, change, value } = props;
   return (
      <div className='filter-item'>
         <span>{label}</span>
         <FilterInput type={type} change={change} value={value} />
      </div>
   );
}

function FilterInput(props) {
   const { type, change, value } = props;
   switch (type) {
      case 'text':
         return <input type='text' class='form-control curve' onChange={change} value={value} />;
      case 'select':
         return (
            <select class='form-select curve' value={value} onChange={change}>
               <option value=''>--- select one ---</option>
            </select>
         );
      case 'date':
         return <input type='date' class='form-control curve' onChange={change} />;
      default:
         return <input type='text' class='form-control' onChange={change} value={value} />;
   }
}

FilterItem.defaultProps = {
   type: 'text',
   change: (e) => {},
   value: '',
   label: '',
};
Filter.defaultProps = {
   reset: (e) => {},
   filter: (e) => {},
};
export { Filter, FilterItem };
