import React from 'react';

//components
import { DataTable, DataTableHead, DataTableFooter, DataTableBody, Table, TableHead, TableBody, TableRaw, TableData } from '../../components/DataTable';
import Badge from '../../components/Badge';
import { Filter, FilterItem } from '../../components/Filter';
function SalesItem(props) {
   return (
      <div className='app-content'>
         <DataTable>
            <DataTableHead title='All Sales Items' />

            <DataTableBody>
               <Filter>
                  <FilterItem label='Barcode' />
                  <FilterItem label='Min Price' />
                  <FilterItem label='Max Price' />
                  <FilterItem label='Name' />
                  <FilterItem label='Batch No' type='select' />
                  <FilterItem label='Category' type='select' />
                  <FilterItem label='Sub Category' type='select' />
                  <FilterItem label='Delivery' type='select' />
                  <FilterItem label='Delivery Date' type='date' />
               </Filter>
               <Table>
                  <TableHead head='Barcode,Name,Quantity,Price,Invoice No,Customer,Date' />
                  <TableBody>
                     <TableRaw>
                        <TableData>P25</TableData>
                        <TableData>Pepsi</TableData>
                        <TableData>12</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>IN102</TableData>
                        <TableData>Ahk pvt</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>P25</TableData>
                        <TableData>Pepsi</TableData>
                        <TableData>12</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>IN102</TableData>
                        <TableData>Ahk pvt</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>P25</TableData>
                        <TableData>Pepsi</TableData>
                        <TableData>12</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>IN102</TableData>
                        <TableData>Ahk pvt</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>P25</TableData>
                        <TableData>Pepsi</TableData>
                        <TableData>12</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>IN102</TableData>
                        <TableData>Ahk pvt</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>P25</TableData>
                        <TableData>Pepsi</TableData>
                        <TableData>12</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>IN102</TableData>
                        <TableData>Ahk pvt</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>P25</TableData>
                        <TableData>Pepsi</TableData>
                        <TableData>12</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>IN102</TableData>
                        <TableData>Ahk pvt</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>P25</TableData>
                        <TableData>Pepsi</TableData>
                        <TableData>12</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>IN102</TableData>
                        <TableData>Ahk pvt</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>P25</TableData>
                        <TableData>Pepsi</TableData>
                        <TableData>12</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>IN102</TableData>
                        <TableData>Ahk pvt</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>P25</TableData>
                        <TableData>Pepsi</TableData>
                        <TableData>12</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>IN102</TableData>
                        <TableData>Ahk pvt</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>P25</TableData>
                        <TableData>Pepsi</TableData>
                        <TableData>12</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>IN102</TableData>
                        <TableData>Ahk pvt</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>P25</TableData>
                        <TableData>Pepsi</TableData>
                        <TableData>12</TableData>
                        <TableData>Rs 1500.00</TableData>
                        <TableData>IN102</TableData>
                        <TableData>Ahk pvt</TableData>
                        <TableData>12/Dec/2021</TableData>
                     </TableRaw>
                  </TableBody>
               </Table>
            </DataTableBody>
            <DataTableFooter></DataTableFooter>
         </DataTable>
      </div>
   );
}

export default SalesItem;
