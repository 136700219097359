import React from 'react';

//components
import { DataTable, DataTableHead, DataTableFooter, DataTableBody, Table, TableHead, TableBody, TableRaw, TableData } from '../../components/DataTable';
import Badge from '../../components/Badge';
import CreateCustomer from './CreateCustomer';
import UpdateCustomer from './UpdateCustomer';
import DeleteCustomer from './DeleteCustomer';
import { Filter, FilterItem } from '../../components/Filter';

function Customers(props) {
   return (
      <div className='app-content'>
         <DataTable>
            <DataTableHead title='All Customers' sub='Total : 1520'>
               <CreateCustomer />
            </DataTableHead>
            <DataTableBody>
               <Filter>
                  <FilterItem label='Barcode' />
                  <FilterItem label='Min Price' />
                  <FilterItem label='Max Price' />
                  <FilterItem label='Name' />
                  <FilterItem label='Batch No' type='select' />
                  <FilterItem label='Category' type='select' />
                  <FilterItem label='Sub Category' type='select' />
                  <FilterItem label='Delivery' type='select' />
                  <FilterItem label='Delivery Date' type='date' />
               </Filter>
               <Table>
                  <TableHead head='CID,Name,Type,Contact,Address,City,Email,Reg No,Status,CP1 Num,CP1 Name,CP2 Num,CP2 Name,Action' />
                  <TableBody>
                     <TableRaw>
                        <TableData>10</TableData>
                        <TableData>sakeen</TableData>
                        <TableData>Best</TableData>
                        <TableData>0771234567</TableData>
                        <TableData>241/c new road</TableData>
                        <TableData>Kalmunai</TableData>
                        <TableData>saky@gmail.com</TableData>
                        <TableData>R102v4</TableData>
                        <TableData>
                           <Badge title='active' cls='bg-success' />
                        </TableData>
                        <TableData>test1</TableData>
                        <TableData>0770253652</TableData>
                        <TableData>test2</TableData>
                        <TableData>0770253652</TableData>
                        <TableData>
                           <div className='action-wrapper'>
                              <DeleteCustomer />
                              <UpdateCustomer />
                           </div>
                        </TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>10</TableData>
                        <TableData>sakeen</TableData>
                        <TableData>Best</TableData>
                        <TableData>0771234567</TableData>
                        <TableData>241/c new road</TableData>
                        <TableData>Kalmunai</TableData>
                        <TableData>saky@gmail.com</TableData>
                        <TableData>R102v4</TableData>
                        <TableData>
                           <Badge title='active' cls='bg-success' />
                        </TableData>
                        <TableData>test1</TableData>
                        <TableData>0770253652</TableData>
                        <TableData>test2</TableData>
                        <TableData>0770253652</TableData>
                        <TableData>
                           <div className='action-wrapper'>
                              <DeleteCustomer />
                              <UpdateCustomer />
                           </div>
                        </TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>10</TableData>
                        <TableData>sakeen</TableData>
                        <TableData>Best</TableData>
                        <TableData>0771234567</TableData>
                        <TableData>241/c new road</TableData>
                        <TableData>Kalmunai</TableData>
                        <TableData>saky@gmail.com</TableData>
                        <TableData>R102v4</TableData>
                        <TableData>
                           <Badge title='active' cls='bg-success' />
                        </TableData>
                        <TableData>test1</TableData>
                        <TableData>0770253652</TableData>
                        <TableData>test2</TableData>
                        <TableData>0770253652</TableData>
                        <TableData>
                           <div className='action-wrapper'>
                              <DeleteCustomer />
                              <UpdateCustomer />
                           </div>
                        </TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>10</TableData>
                        <TableData>sakeen</TableData>
                        <TableData>Best</TableData>
                        <TableData>0771234567</TableData>
                        <TableData>241/c new road</TableData>
                        <TableData>Kalmunai</TableData>
                        <TableData>saky@gmail.com</TableData>
                        <TableData>R102v4</TableData>
                        <TableData>
                           <Badge title='active' cls='bg-success' />
                        </TableData>
                        <TableData>test1</TableData>
                        <TableData>0770253652</TableData>
                        <TableData>test2</TableData>
                        <TableData>0770253652</TableData>
                        <TableData>
                           <div className='action-wrapper'>
                              <DeleteCustomer />
                              <UpdateCustomer />
                           </div>
                        </TableData>
                     </TableRaw>
                     <TableRaw>
                        <TableData>10</TableData>
                        <TableData>sakeen</TableData>
                        <TableData>Best</TableData>
                        <TableData>0771234567</TableData>
                        <TableData>241/c new road</TableData>
                        <TableData>Kalmunai</TableData>
                        <TableData>saky@gmail.com</TableData>
                        <TableData>R102v4</TableData>
                        <TableData>
                           <Badge title='active' cls='bg-success' />
                        </TableData>
                        <TableData>test1</TableData>
                        <TableData>0770253652</TableData>
                        <TableData>test2</TableData>
                        <TableData>0770253652</TableData>
                        <TableData>
                           <div className='action-wrapper'>
                              <DeleteCustomer />
                              <UpdateCustomer />
                           </div>
                        </TableData>
                     </TableRaw>
                  </TableBody>
               </Table>
            </DataTableBody>
            <DataTableFooter></DataTableFooter>
         </DataTable>
      </div>
   );
}

export default Customers;
