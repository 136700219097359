import React from 'react';
import { Accordion, useAccordionButton, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

//assets image
import logo from '../assets/img/logo.png';
//type
import type from '../constant/type';
class SideNav extends React.Component {
   handleClickLogout(e) {
      const { dispatch } = this.props;
      const { LOG_OUT } = type;
      dispatch({
         type: LOG_OUT,
      });

      window.location.assign('/#/login');
   }
   render() {
      return (
         <div className='side-nav-container'>
            <div className='app-logo uncopy'>
               <img src={logo} alt='app logo' className='uncopy' />
            </div>
            <Accordion className='nav-icon-wrapper'>
               <NavIcon ico='apps-outline' text='dashboard' to='/' eventKey='1' />
               <NavIcon ico='document-text-outline' text='reportes' to='/reportes' eventKey='2' />
               <NavIcon
                  ico='server-outline'
                  text='products'
                  to='/products'
                  eventKey='3'
                  subnav={true}
                  subnavele={() => {
                     return (
                        <>
                           <SubNavItem to='/products/categories' text='Categories' />
                        </>
                     );
                  }}
               />
               <NavIcon
                  ico='storefront-outline'
                  text='stock in'
                  to='/stock'
                  eventKey='4'
                  subnav={true}
                  subnavele={() => {
                     return (
                        <>
                           <SubNavItem to='/sales/item' ico='cash-outline' text='Stock in report' />
                           <SubNavItem to='/sales/item' ico='cash-outline' text='Total stock out' />
                           <SubNavItem to='/sales/item' ico='cash-outline' text='Stock report' />
                        </>
                     );
                  }}
               />
               <NavIcon
                  ico='cash-outline'
                  text='sales'
                  to='/sales'
                  eventKey='5'
                  subnav={true}
                  subnavele={() => {
                     return (
                        <>
                           <SubNavItem to='/sales/item' ico='cash-outline' text='Sales item' />
                           <SubNavItem to='/sales/item' ico='cash-outline' text='Sales out' />
                           <SubNavItem to='/sales/item' ico='cash-outline' text='Today sales' />
                           <SubNavItem to='/sales/item' ico='cash-outline' text='Most sales' />
                           <SubNavItem to='/sales/item' ico='cash-outline' text='This month sales' />
                           <SubNavItem to='/sales/item' ico='cash-outline' text='Sales return' />
                        </>
                     );
                  }}
               />
               <NavIcon
                  ico='people-outline'
                  text='customers'
                  to='/customers'
                  eventKey='6'
                  subnav={true}
                  subnavele={() => {
                     return (
                        <>
                           <SubNavItem to='/sales/item' ico='cash-outline' text='All customers' />
                           <SubNavItem to='/sales/item' ico='cash-outline' text='Active customers' />
                           <SubNavItem to='/sales/item' ico='cash-outline' text='Blocked customers' />
                        </>
                     );
                  }}
               />

               <Divider />

               <NavIcon ico='settings-outline' text='settings' to='/settin' eventKey='7' />
               <NavIcon
                  ico='person-outline'
                  text='Account'
                  to='/#'
                  eventKey='8'
                  subnav={true}
                  subnavele={() => {
                     return (
                        <>
                           <SubNavItem to='/sales/item' ico='cash-outline' text='Expenses' />
                           <SubNavItem to='/sales/item' ico='cash-outline' text='Purchasings' />
                        </>
                     );
                  }}
               />

               <div
                  onClick={(e) => {
                     this.handleClickLogout(e);
                  }}
               >
                  <NavIcon ico='exit-outline' text='logout' to='/logout' />
               </div>
            </Accordion>
         </div>
      );
   }
}

function NavIcon(props) {
   const { ico, text, to, eventKey, subnav, subnavele } = props;
   const decoratedOnClick = useAccordionButton(eventKey);

   if (subnav) {
      return (
         <div className='nav-icon-item-wrapper'>
            <NavLink className={({ isActive }) => (isActive ? 'nav-icon uncopy active' : 'nav-icon uncopy')} to={to} onClick={decoratedOnClick}>
               <span>
                  <ion-icon name={ico}></ion-icon>
               </span>
               <span className='uncopy'>{text}</span>
               <span>
                  <ion-icon name='chevron-down-outline'></ion-icon>
               </span>
            </NavLink>
            <Accordion.Collapse eventKey={eventKey} className='sub-nav-item-wrapper'>
               <div className='sub-nav-item'>{subnavele()}</div>
            </Accordion.Collapse>
         </div>
      );
   } else {
      return (
         <div className='nav-icon-item-wrapper'>
            <NavLink className={({ isActive }) => (isActive ? 'nav-icon uncopy active' : 'nav-icon uncopy')} to={to}>
               <span>
                  <ion-icon name={ico}></ion-icon>
               </span>
               <span className='uncopy'>{text}</span>
               <span></span>
            </NavLink>
         </div>
      );
   }
}
NavIcon.defaultProps = {
   to: '/404',
   text: 'unknown',
   ico: 'alert-circle-outline',
   eventKey: '0',
   subnav: false,
};

function SubNavItem(props) {
   const { text, to } = props;
   return (
      <NavLink className={({ isActive }) => (isActive ? 'sub-nav-icon subactive' : 'sub-nav-icon')} to={to}>
         <span className='uncopy'>{text}</span>
      </NavLink>
   );
}
SubNavItem.defaultProps = {
   text: '404',
   to: '/404',
};

class Divider extends React.Component {
   render() {
      return <div className='nav-divider'></div>;
   }
}

const mapStateToProps = (state) => {
   return {
      nav: state.nav,
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      dispatch: dispatch,
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
