import React from "react";

class Settings extends React.Component{
    render(){
        return(
            <div>
                <p className="text-white">Settings</p>
            </div>
        )
    }
}

export default Settings;