import React from 'react';

function ActionButton(props) {
   const { text, click } = props;
   return (
      <button className='btn btn-danger h-100 curve' style={{ fontSize: 15, fontWeight: 'bold', letterSpacing: 1 }} onClick={click}>
         {text}
      </button>
   );
}

ActionButton.defaultProps = {
   click: (e) => {},
   text: '404',
};

export { ActionButton };
