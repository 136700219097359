import React from 'react';
import { useDispatch } from 'react-redux';
//components

import { DataTable, DataTableHead, DataTableFooter, DataTableBody } from '../../components/DataTable';
import { Table, TableHead, TableBody, TableRaw, TableData } from '../../components/AppTable';
import { ActionButton } from '../../components/ActionButton';
import { TableActionBtn, TableActionWrapper } from '../../components/TableAction';
import CreateProduct from './CreateProduct';
import UpdateProduct from './UpdateProduct';
import DeleteProduct from './DeleteProduct';
import { Filter, FilterItem } from '../../components/Filter';
//action
import { showPopup } from '../../redux/action/popup';
//constant
import { popupkey } from '../../constant/popupkey';

function Products(props) {
   const dispatch = useDispatch();
   const filter = {
      barcode: {
         type: 'text',
         change: (e) => {},
         value: '',
      },
      sub_category: {
         type: 'date',
         change: (e) => {},
         value: '',
      },
   };

   return (
      <div className='app-content'>
         <DataTable>
            <DataTableHead title='All Products' sub='Total : 1520'>
               <ActionButton
                  text='CREATE PRODUCT'
                  click={(e) => {
                     showPopup(dispatch, popupkey.C_PRODUCT);
                  }}
               />
            </DataTableHead>

            <DataTableBody>
               <Table>
                  <TableHead filter={filter} head='Barcode,PID,Category,Sub Category,Name1,Name2,Qty,Cost Price,Max Price,Sell Price,Cash Price,Spec Price,Action' />
                  <TableBody>
                     <TableRaw>
                        <TableData>BC1025</TableData>
                        <TableData>15</TableData>
                        <TableData>Drink</TableData>
                        <TableData>Soda</TableData>
                        <TableData>Pepsi</TableData>
                        <TableData>Pepsi2</TableData>
                        <TableData>150</TableData>
                        <TableData>Rs 50.00</TableData>
                        <TableData>Rs 70.00</TableData>
                        <TableData>Rs 65.00</TableData>
                        <TableData>Rs 60.00</TableData>
                        <TableData>Rs 67.00</TableData>
                        <TableData>
                           <TableActionWrapper>
                              <TableActionBtn ico='create-outline' click={(e) => showPopup(dispatch, popupkey.U_PRODUCT)} />
                              <TableActionBtn ico='trash-outline' click={(e) => showPopup(dispatch, popupkey.D_PRODUCT)} />
                           </TableActionWrapper>
                        </TableData>
                     </TableRaw>
                  </TableBody>
               </Table>
            </DataTableBody>
            <DataTableFooter></DataTableFooter>
         </DataTable>

         {/*-------------------------------------[ POPUP COMPONENTS]------------------------------------*/}
         <CreateProduct />
         <UpdateProduct />
         <DeleteProduct />
         {/*-------------------------------------[ END POPUP COMPONENTS]------------------------------------*/}
      </div>
   );
}

export default Products;
