import React from 'react';

function Footer(props) {
   return (
      <div className='app-footer-container'>
         <div className='app-footer'>
            <div className='foter-content'>© Copyright 2021 Abc | v1.0.0 | Developed by royaltech.</div>
         </div>
      </div>
   );
}

export default Footer;
