import { useState } from 'react';

import PopUp from '../../components/Popup';
import { ActionButton } from '../../components/ActionButton';
import { Row, Col } from 'react-bootstrap';
import Alert from '../../components/Alert';
import { FormItem, Text, Select, InputDate } from '../../components/CustomFormItem';
import { DateExtract } from '../../lib/DateExtract';
function AddStock(props) {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const date = new DateExtract();

   return (
      <>
         <ActionButton text='ADD STOCK' click={handleShow} />
         <PopUp show={show} close={handleClose} title='ADD STOCK IN TO STORE'>
            <Row className='w-100 h-100 m-0'>
               <Col md={12}>
                  <Alert msg='Successfully stock added.' status='1' show={true} />
               </Col>

               <Col md={7}>
                  <FormItem label='Barcode'>
                     <Text />
                  </FormItem>
               </Col>
               <Col md={5}>
                  <FormItem label='Delivery Mode'>
                     <Select />
                  </FormItem>
               </Col>
               <Col md={12}>
                  <FormItem label='Product Name'>
                     <Text />
                  </FormItem>
               </Col>
               <Col md={6}>
                  <FormItem label='Quantity'>
                     <Text value={1} />
                  </FormItem>
               </Col>
               <Col md={6}>
                  <FormItem label='New Price'>
                     <Text />
                  </FormItem>
               </Col>

               <Col md={6}>
                  <FormItem label='Receiver'>
                     <Text />
                  </FormItem>
               </Col>
               <Col md={6}>
                  <FormItem label='Received Date'>
                     <Text type='datetime-local' value={date.humanReadbleDate(DateExtract.current(), 'y-m-d h:m')} />
                  </FormItem>
               </Col>

               <Col md={3}>
                  <FormItem label='Supplier'>
                     <Text />
                  </FormItem>
               </Col>
               <Col md={9}>
                  <FormItem label='Supplier Name'>
                     <Text />
                  </FormItem>
               </Col>

               <Col md={12}>
                  <FormItem label='Stock Remark'>
                     <Text />
                  </FormItem>
               </Col>

               <Col>
                  <div className='w-100 d-flex justify-content-end'>
                     <button className='btn btn-primary'>
                        <span style={{ marginLeft: 5 }}>Add Stock</span>
                     </button>
                  </div>
               </Col>
            </Row>
         </PopUp>
      </>
   );
}

export default AddStock;
